
html,
body {
  width: 100%;
  height: 100%; }

body {
  background: $base-background-color;
  padding-top: 35px; }

figure {
  margin: 0;
  padding: 0; }

.main-container {
  background: darken($base-background-color, 3%);
  padding: 15px 0; }

.container {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-left: $base-spacing;
  padding-right: $base-spacing;

  @include media($desktop) {
    max-width: 1240px; } }

td.code {
  border-bottom: 0px;
  padding: 0; }

td.gutter {
  border-bottom: 0px;
  padding: 0;
  width: 2.1em; }

table.rouge-table {
  margin: 0px; }


.webmentions {
  font-size: 16px;
  font-size: 1rem;
  margin-top: .6875rem; }


.webmentions__list {
  margin: 0;
  padding: 0; }


.webmentions__item {
  clear: both;
  border-top: 1px solid #DDD;
  margin: 0;
  padding: .5rem 0 .5rem 2.75rem;
  min-height: 48px;
  min-height: 3rem;
  position: relative; }


.webmentions__item:first-child {
  border-top: 0; }


.webmention__author {
  font-size: 14px;
  font-size: .875rem;
  font-weight: 700; }

.webmention__author__photo {
  border-radius: 3px;
  display: block;
  width: 32px;
  width: 2rem;
  height: 32px;
  height: 2rem;
  position: absolute;
  top: 22px;
  top: .5rem;
  left: 0; }


.webmention__content, .webmention__meta, .webmention__title {
  margin: 0; }


.webmention__meta {
  color: #505050;
  font-size: 14px;
  font-size: .875rem; }


.webmention--activity .webmention__author, .webmention--activity .webmention__title {
  display: inline; }


.webmention--like, .webmention--repost {
  border: 0;
  display: inline;
  margin: 0 5px 0 0;
  padding: 0; }


.webmention--like .webmention__author, .webmention--repost .webmention__author {
  display: inline; }


.webmention--like .webmention__author__photo, .webmention--repost .webmention__author__photo {
  border: 1px solid;
  border-radius: 50%;
  display: inline;
  max-width: 48px;
  margin: 0;
  position: static; }


.webmention--like .webmention__author a, .webmention--repost .webmention__author a {
  background-image: none!important; }

.webmentions__list {
  list-style: none; }

.webmention--author-starts .h-card .p-name {
  position: absolute!important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px); }

p.centered {
  text-align: center; }

.post {
  * a {
    text-decoration: underline; } }

.page {
  * a {
    text-decoration: underline; } }

a.u-url {
  text-decoration: none; }

h2 {
  margin-top: 1.5em; }

li {
  margin-top: 0.7em; }

nav {
  ul {
    li {
      margin-top: 0em; } } }
