.site-header {
  background-color: $white-color;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid rgba($light-gray-color, .5);
  @include position(fixed, 0 0 null 0);
  min-height: 40px;
  width: 100%;
  z-index: 1000;

  @extend %clearfix;

  .logo, a {
    text-transform: lowercase; }

  .navbar {
    float: right;
    padding-top: 3px;
    padding-bottom: 3px; }

  a {
    color: $medium-gray-color;

    &:hover,
    &:focus,
    &:active {
      color: $dark-gray-color; } } }

.logo {
  margin: 0;
  padding: 0;
  float: left;
  font-weight: $font-weight-normal;
  font-size: rem(20);
  @include position(relative, 0.3em null null .5em);
  z-index: 100;

  span {
    color: $primary-color; } }
