
.social {
  padding: $base-spacing 0;
  @extend %clearfix;

  li {
    display: inline-block;
    padding: 0 ($base-spacing / 2.5); }

  a {
    color: $medium-gray-color;

    &:hover,
    &:active,
    &:focus {
      color: $primary-color; } }

  i {
    font-size: 1.6em; } }
